footer {
  /* position: sticky;
  bottom: 0;
  left: 0; */
  border-top: 1px solid black;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  z-index: 3;
  background: rgb(34,39,51);
  background: linear-gradient(0deg, rgba(34,39,51,1) 0%, rgba(40,50,62,1) 50%);
}

.layout-children {
  max-width: 100vw;
  overflow-x: hidden;
}

.footer-elements {
  padding: 0 50px 0 50px;
}
