/* .headshot {
  width: 500px;
  height: 500px;
  margin-right: 25px;
  object-fit:contain;
  filter: drop-shadow(0 0 5px rgb(23, 23, 23));
} */


.aboutme-container {
  max-width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #000000;
  background: linear-gradient(180deg, #0083da 0%, #0099ff 150%);
  box-shadow:0 0 5px black;
}

.nav-links-container {
  display: flex;
  width: 100vw;
  height: 10vh;
  justify-content: flex-end;
  align-items: center;
  padding-right: 50px;
}

.nav-links {
  font-size: 22px;
  color: #fafbfc;
}
a {
  text-decoration: none;
  font-size: 25px;
  color: #fafbfc;
}

.greeting {
  font-size: 65px;
  color: #f8f8f8;
  
}

.tagline {
  font-size: 45px;
  color: #f8f8f8;
}

.greeting-container {
  height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.arrow-navigation {
  height: 60px;
  width: 110px;
  margin-bottom: 40px;
  color: transparent;
  text-shadow: 0 0 0 #3594fa;
  align-self: flex-end;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
}

.arrow-navigation:hover {
  cursor: pointer;
}

.bounce-5 {
  animation-name: bounce-5;
  animation-timing-function: ease;
}
@keyframes bounce-5 {
  0%   { transform: scale(1,1)    translateY(0); }
  10%  { transform: scale(1.1,.9) translateY(0); }
  30%  { transform: scale(.9,1.1) translateY(-70px); }
  50%  { transform: scale(1,1)    translateY(0); }
  57%  { transform: scale(1,1)    translateY(-8px); }
  64%  { transform: scale(1,1)    translateY(0); }
  100% { transform: scale(1,1)    translateY(0); }
}

.scroll-button-container {
  max-width: 100vw;
  height: 15vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;
}


@media only screen and (max-width: 900px) {
  .greeting {
    font-size: 55px;
    color: #f8f8f8;
  }

  .tagline {
    font-size: 45px;
    color: #f8f8f8;
  }
}

@media only screen and (max-width: 750px) {
  .greeting {
    font-size: 45px;
    color: #f8f8f8;
  }

  .tagline {
    font-size: 35px;
    color: #f8f8f8;
  }
}

@media only screen and (max-width: 600px) {
  .greeting {
    font-size: 25px;
    color: #f8f8f8;
  }

  .tagline {
    font-size: 22px;
    color: #f8f8f8;
  }

  .arrow-navigation {
    height: 40px;
    width: 85px;
    color: transparent;
    text-shadow: 0 0 0 #3594fa;
    align-self: flex-end;
    animation-duration: 1.8s;
    animation-iteration-count: infinite;
  }
  .nav-links-container {
  padding-right: 15px;
  
  }

  .nav-links {
    font-size: 18px;
    color: #fafbfc;
  }
}