.big-skills-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.skills-container {
  height: 85vh;
  max-width: 100vw;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 100px;
}

.skill-image {
  height: 100px;
  width: 100px;
}

.skill {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 25px;
  width: 17vw;
} 

.skill-edited {
  height: 100px;
  width: 100px;
  object-fit:contain;
}

.skills {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 55vh;
  /* width: 90vw; */
}

.skill-git {
  transform: rotate(180deg);
  height: 100px;
  width: 100px;
  object-fit:contain;
}

.skills-label {
  font-size: 70px;
  color :#162642;
  /* font-weight: 600; */
}

.down-arrow-navigation {
  height: 90px;
  width: 95px;
  color: transparent;
  text-shadow: 0 0 0 #3594fa;
  align-self: flex-end;
  padding-right: 5px;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  fill: black;
}

.down-arrow-navigation:hover {
  cursor: pointer;
}

.skills-scroll-button-container {
  max-width: 100vw;
  height: 15vh;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 15px;
}


@media only screen and (max-width: 900px) {

  .skills-container {
    height: 85vh;
    max-width: 100vw;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 100px;;
  }

  .skill {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 25px;
    width: 140px;
  } 
}

@media only screen and (max-width: 550px) {
  .big-skills-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .skills-container {
    height: 90vh;
    max-width: 100vw;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 10px;;
  }

  .skill {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 25px;
    width: 130px;
    margin: 5px 10px 5px 10px;
  } 
  
  .skill-image {
    height: 60px;
    width: 60px;
  }
  
  .skill-edited {
    height: 80px;
    width: 80px;
    object-fit:contain;
  }
  
  .skills {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 80vh;
    overflow: hidden;
    /* width: 90vw; */
  }
  
  .skill-git {
    transform: rotate(180deg);
    height: 80px;
    width: 80px;
    object-fit:contain;
  }
  
  .skills-label {
    font-size: 40px;
    color :#162642;
    /* font-weight: 600; */
  }
  .down-arrow-navigation {
    height: 60px;
    width: 80px;
    color: transparent;
    text-shadow: 0 0 0 #3594fa;
    align-self: flex-end;
    animation-duration: 1.8s;
    animation-iteration-count: 0;
  }
  .skills-scroll-button-container {
    max-width: 100vw;
    height: 7vh;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-right: 15px;
  }
}