.project-image {
width: 55vw;
}

.projects-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height:100vh;
}
.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100vh;
}

.multi-images-project {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  width: 55vw;
  height: auto;
  max-height: 55vh;
  grid-area: P;
  overflow: hidden;
}

.project-title {
  font-size: 40px;
  height: auto;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* justify-content: center; */
  /* width: 38vw; */
}

.project-info {
 
  height: auto;
  display: flex;
  /* justify-content: center; */
  font-size: 18px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
}

.project-links {
  height: auto;
  grid-area: L;
  display: flex;
  justify-content: space-between;
  width: 350px;
}

.project-link {
  font-size: 20px;
  width: 115px;
  height: 40px;
  background-color: #9900FF  ;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  filter: drop-shadow(0 0 1px grey);
}

.project-link:hover {
  transform: scale(1.03);
  box-shadow:  0 0 2px grey;
  transition: .1s;
  background-color: #7e02d1  ;
}

.project-caption {
  grid-area: D;
  height: auto;
  display: flex;
  width: 60vw;
  /* justify-content: center; */
  font-size: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
}


.project-buttons {
  grid-area: B;
  display: flex;
  justify-content: space-between;
  width: 350px;
}
.next-button {
  font-size: 20px;
  width: 115px;
  height: 40px;
  background-color: #0099ff;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  filter: drop-shadow(0 0 1px grey);
 
}

.next-button:hover {
  transform: scale(1.03);
  box-shadow:  0 0 2px grey;
  transition: .1s;
  cursor: pointer;
  background-color: #0084dc;
}

.back-button {
  font-size: 20px;
  width: 115px;;
  height: 40px;
  background-color: #0099ff;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  filter: drop-shadow(0 0 1px grey);
}

.back-button:hover {
  transform: scale(1.03);
  box-shadow:  0 0 2px grey;
  transition: .1s;
  cursor: pointer;
  background-color: #0084dc;
}

.fade-enter{
  opacity: 0;
  transform: translateX(-100%);
}
.fade-exit{
  opacity: 1;
  transform: translateX(0%);
}
.fade-enter-active{
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit-active{
  opacity: 0;
  transform: translateX(100%);
}
.fade-enter-active,
.fade-exit-active{
  transition: opacity 350ms, transform 350ms;
}

.fades-enter{
  opacity: 0;
  transform: translateX(100%);
}
.fades-exit{
  opacity: 1;
  transform: translateX(0%);
}
.fades-enter-active{
  opacity: 1;
  transform: translateX(0%);
}
.fades-exit-active{
  opacity: 0;
  transform: translateX(-100%);
}
.fades-enter-active,
.fades-exit-active{
  transition: opacity 350ms, transform 350ms;
}

@media only screen and (max-width: 1050px) {
  
}

@media only screen and (max-width: 730px) {
  .project-image {
    width: 98vw;
    margin-top: 5px;
    }
    
    .projects-container {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      padding-bottom: 0px;
      width: 100vw;
      height:90vh;
    }
    .carousel-container {
     display: flex;
     flex-direction: column;
     justify-content: space-around;
     height: 85vh;
    }
    .multi-images-project {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      width: 100vw;
      height: auto;
      overflow: hidden;
    }
    .project-title {
      grid-area: T;
      font-size: 40px;
      width: 100vw;
      height: auto;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding-left: 0;
    }
    .project-info {
      height: auto;
      grid-area: S;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      /* justify-content: center; */
      font-size: 18px;
      font-weight: 600;
      width: 95vw;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 0;
    }
    .project-links {
      height: auto;
     
      display: flex;
      justify-content: space-around;
      width: 100vw;
  
      min-width: 350px;
      padding-left: 0px;
    }
    .project-link {
      font-size: 20px;
      width: 115px;
      height: 40px;
      background-color: #9900FF  ;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      filter: drop-shadow(0 0 1px grey);
      margin-right: 0px;
    }
    .project-caption {
      grid-area: D;
      height: auto;
      display: flex;
      justify-content: center;
      font-size: 20px;
      width: 100vw;
      padding-left: 10px;
      overflow:visible;
      text-overflow: none;
      padding-right: 5px;
    }
    .project-buttons {
      grid-area: B;
      display: flex;
      justify-content: space-around;
      width: 100vw;
      padding-left: 0px;
      min-width: 350px;
      height: auto;
    }
    .next-button {
      font-size: 20px;
      width: 115px;
      height: 40px;
      background-color: #0099ff;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      filter: drop-shadow(0 0 1px grey);
     
    }
    .back-button {
      font-size: 20px;
      width: 115px;;
      height: 40px;
      background-color: #0099ff;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      filter: drop-shadow(0 0 1px grey);
      margin-right: 0px;
    }
}